/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
}

@layer components {
  /* breadcrumb */
  ul.breadcrumb li {
    @apply inline;
  }
  ul.breadcrumb li + li:before {
    @apply px-2 content-['>'];
  }
  ul.breadcrumb a {
    @apply cursor-pointer;
  }

  /* filter-table */
  .filter-table {
    @apply border-y-2 border-black;
  }
  .filter-table tr td {
    @apply p-6;
  }
  .filter-table td {
    @apply border-t;
  }
  .filter-table td:first-child {
    @apply w-44 bg-gray-100;
  }

  /* data-grid */
  .data-grid {
    @apply border-l border-t bg-white;
  }
  .data-grid tr {
    @apply border-b border-r p-2;
  }
  .data-grid td {
    @apply border-b border-r p-2;
  }
  .data-grid th {
    @apply border-b border-r bg-gray-100 p-2;
  }

  /* button */
  .button-green {
    @apply mb-2 rounded-md bg-green-500 text-base font-semibold text-white;
  }
  .button-red {
    @apply mb-2 rounded-md bg-red-500 text-base font-semibold text-white;
  }
  .button-white {
    @apply mb-2 rounded-md border bg-white text-base font-semibold text-black;
  }
  .button-black {
    @apply mb-2 rounded-md bg-black text-base font-semibold text-white;
  }
}
